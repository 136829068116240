var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(" " + _vm._s(_vm.pageheading.toLocaleUpperCase()) + " ")]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _c(
          "div",
          {
            staticStyle: { overflow: "hidden" },
            attrs: { id: "body-content-area" },
          },
          [
            _vm.isLoading
              ? _c("div", [_c("Loading")], 1)
              : _c("div", [
                  _c("div", { attrs: { id: "pagelayout" } }, [
                    _vm.errors && _vm.errors.length
                      ? _c(
                          "div",
                          _vm._l(_vm.errrors, function (error) {
                            return _c("div", { key: error }, [
                              _vm._v(_vm._s(error)),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.states && _vm.states.length > 0
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "grid-container" },
                            [
                              _c("div", { staticClass: "bottom-bar" }, [
                                _c("div", { staticClass: "buttons" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button is-accent",
                                      attrs: {
                                        disabled: !_vm.$hasPermissions(
                                          _vm.clientSession,
                                          ["REPORT_CONFIGURATION"],
                                          2
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$router.push({
                                            name: "NewStateConfig",
                                            model: null,
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-plus",
                                        staticStyle: {
                                          "margin-right": "0.75rem",
                                          "margin-left": "0",
                                        },
                                      }),
                                      _vm._v("Add State "),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm.maxHeight > 0
                                ? _c("Grid", {
                                    staticClass: "states-grid",
                                    style: {
                                      maxheight: _vm.maxHeight + "px",
                                      height: _vm.maxHeight + "px",
                                    },
                                    attrs: {
                                      "data-items": _vm.states,
                                      columns: _vm.columns,
                                      "selected-field": _vm.selectedField,
                                      sortable: true,
                                      pageable: false,
                                      "page-size": _vm.minResults,
                                      total: _vm.totalRecords,
                                      sort: _vm.sort,
                                    },
                                    on: {
                                      headerselectionchange:
                                        _vm.onHeaderSelectionChange,
                                      selectionchange: _vm.onSelectionChange,
                                      pagechange: _vm.pageChangeHandler,
                                      sortchange: _vm.sortchange,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "nameCell",
                                          fn: function (ref) {
                                            var props = ref.props
                                            return [
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "has-text-accent",
                                                      attrs: {
                                                        to: {
                                                          name: "StateConfigEdit",
                                                          params: {
                                                            id:
                                                              "" +
                                                              props.dataItem
                                                                .stateKey,
                                                            model:
                                                              "" +
                                                              JSON.stringify(
                                                                props.dataItem
                                                              ),
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            props.dataItem
                                                              .stateAbbreviation
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1777878682
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }